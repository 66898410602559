import React, { Component } from "react";
import Button from "../../../Components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faCheck,
  faTriangleExclamation,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import AttendanceService from "../../../Services/AttendanceService";
import { calculateAge, calculateEnrollment } from "../../../Utils";

export default class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: props.eventId,
      responseOK: false,
    };
  }

  componentDidMount() {}

  makeAttendance = () => {
    AttendanceService.setAttendance(
      this.props.eventId,
      this.props.scanData.member.id
    )
      .then((res) => {
        this.setState({ responseOK: true });
        //this.props.closeCallBack();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  checkValidPersonId = (dni) => {
    var numero, letter, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace("X", 0);
      numero = numero.replace("Y", 1);
      numero = numero.replace("Z", 2);
      letter = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = "TRWAGMYFPDXBNJZSQVHLCKET";
      letra = letra.substring(numero, numero + 1);
      return !(letra != letter);
    } else {
      return false;
    }
  };

  render() {
    const { scanData } = this.props;

    if (!scanData) {
      return (
        <div
          className="w-full border-4 !border-gray-600 h-full bg-black"
          style={{ position: "absolute", top: "0", left: "0", zIndex: "52" }}
        >
          <button
            onClick={this.props.closeCallBack}
            type="button"
            style={{ position: "absolute", right: "15px", top: "15px" }}
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="flex flex-col h-full justify-between">
            <div className="align-middle my-15 pb-7 pt-5 items-center px-8 mt-20 grid justify-center bg-yellow-500 mx-6 rounded-xl">
              <FontAwesomeIcon
                className="text-6xl mb-2 w-full"
                icon={faTriangleExclamation}
              />
              <h1 className="text-4xl w-full text-center">No encontrado</h1>
              <span className="italic text-xs text-center w-full text-gray-100">
                Puede que este miembro no exista o que no esté convocado para
                este evento
              </span>
            </div>
            <div className="flex mt-auto mb-5 justify-center items-center px-2">
              <Button
                onClick={this.props.closeCallBack}
                className="!text-2xl !bg-red-700 !w-full mr-0"
              >
                <FontAwesomeIcon
                  className="mr-3 font-extrabold"
                  icon={faXmark}
                />
                Cerrar
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      const member = scanData.member;
      const status = scanData.status;
      const isValidDNI = this.checkValidPersonId(member.person_id);
      const informationIncomplete =
        member.email === null ||
        member.email === "" ||
        member.email === "null" ||
        member.phone === null ||
        member.phone === "" ||
        member.phone === "null" ||
        member.address === null ||
        member.address === "" ||
        member.address === "null" ||
        member.birthday === null ||
        member.birthday === "" ||
        member.birthday === "null" ||
        member.enrollement_date === null ||
        member.enrollement_date === "" ||
        member.enrollement_date === "null";
      return (
        <div
          className="w-full h-full bg-black"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "52",
          }}
        >
          <button
            onClick={this.props.closeCallBack}
            type="button"
            style={{ position: "absolute", right: "15px", top: "15px" }}
            className="bg-gray-500 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          {this.state.responseOK && (
            <div className="text-5xl flex items-center justify-center text-green-500 h-full">
              <div className="wrapperCheck">
                {" "}
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </div>
          )}
          {!this.state.responseOK && (
            <React.Fragment>
              <div className="flex flex-col h-full justify-between">
                <div className="align-middle my-15 py-10 px-10">
                  <h1 className="text-5xl">{member.name}</h1>
                  <h2 className="text-4xl">{member.lastname}</h2>
                  <h3 className="text-3xl text-center text-gray-400 italic mt-2">
                    {member.person_id.toUpperCase()}
                  </h3>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="bg-gray-200 font-bold text-gray-800 text-center justify-center !text-xs px-2 py-2 rounded-full flex">
                      Edad:
                      <br />
                      {calculateAge(member.birthday)}
                    </div>
                    <div className="bg-gray-200 font-bold text-gray-800 text-center justify-center !text-xs px-2 py-2 rounded-full flex">
                      Antigüedad:
                      <br />
                      {calculateEnrollment(member.enrollement_date)}
                    </div>
                  </div>
                  <div className="mt-2"></div>
                  {(informationIncomplete || !isValidDNI) && (
                    <span className=" border mb-2 border-yellow-500 text-center justify-center font-bold text-yellow-500 py-2 !text-xs px-3 rounded-full flex">
                      <FontAwesomeIcon
                        className="mr-2 text-sm"
                        icon={faTriangleExclamation}
                      />
                      {!isValidDNI
                        ? "DNI No válido"
                        : "Le faltan algunos datos a su perfil"}
                    </span>
                  )}
                  <span
                    className={
                      (member.payment_up_to_date
                        ? "bg-green-800 text-teal-950"
                        : "bg-red-800 text-red-950") +
                      " font-bold text-white text-center justify-center !text-xs px-2 py-2 rounded-full flex"
                    }
                  >
                    <FontAwesomeIcon
                      className="mr-2 text-sm"
                      icon={faSackDollar}
                    />
                    {member.payment_up_to_date
                      ? "Al corriente de pago"
                      : "No está al corriente de pago"}
                  </span>
                  {status === "in" && (
                    <div className="align-middle pb-7 pt-5 items-center px-3 mt-4 grid justify-center bg-yellow-500 rounded-xl">
                      <FontAwesomeIcon
                        className="text-5xl mb-2 w-full"
                        icon={faTriangleExclamation}
                      />
                      <p className="text-2xl w-full text-center tracking-tight font-bold">
                        Miembro ya dentro de la reunión
                      </p>
                    </div>
                  )}
                </div>
                <div className="grid gap-2 px-2 grid-cols-2 mt-auto mb-5 !items-center !justify-center">
                  <Button
                    onClick={this.props.closeCallBack}
                    className="!text-2xl !bg-red-700 w-full mr-0"
                  >
                    <FontAwesomeIcon
                      className="mr-3 font-extrabold"
                      icon={faXmark}
                    />
                    Cerrar
                  </Button>
                  {status !== "in" && (
                    <Button
                      onClick={this.makeAttendance}
                      className="!text-2xl !bg-green-700 w-full mr-0"
                    >
                      <FontAwesomeIcon
                        className="mr-3 font-extrabold"
                        icon={faCheck}
                      />
                      Marcar
                    </Button>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }
  }
}
