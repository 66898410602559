import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_PARENT_APP_HOST + "/api/event/attendance";

class AttendanceServices {
  getAttendanceList(eventId = null) {
    return axios.get(API_BASE_URL + "/get/" + eventId);
  }

  getAttendanceInfo(eventId = null) {
    return axios.get(API_BASE_URL + "/get-info/" + eventId);
  }

  searchAttendance(eventId = null, value = null) {
    return axios.get(API_BASE_URL + "/search/" + eventId, {
      params: { searchValue: value },
    });
  }

  searchAttendanceQR(eventId = null, value = null) {
    return axios.get(API_BASE_URL + "/search-qr/" + eventId, {
      params: { searchValue: value },
    });
  }

  setAttendance(event, member, status = true) {
    return axios.post(API_BASE_URL + "/set", {
      event_id: event,
      member_id: member,
      status: status,
    });
  }
}
const AttendanceService = new AttendanceServices();
export default AttendanceService;
