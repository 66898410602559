import React, { Component } from "react";
import BaseLayout from "../../Layouts/BaseLayout";
import { Link } from "react-router-dom";
import EventService from "../../Services/EventService";
import {
  paginateResults,
  isDatePast,
  isDateDuring,
  isDateToday,
} from "../../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import { Loader, Paginator } from "../../Components";
import GroupOfEvents from "./components/GroupOfEvents";
import EventsListItem from "./components/EventListItem";

export default class EventsList extends Component {
  eventOptions = {
    owner_id: { name: "Owner Id", code: "owner_id" },
    title: { name: "Title", code: "title" },
    description: { name: "Description", code: "description" },
    start_date: { name: "Start Date", code: "start_date" },
    app_access_roles_allowed: {
      name: "App Access Roles Allowed",
      code: "app_access_roles_allowed",
    },
  };

  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      deletedId: null,
      actual_page: params.get("page") != null ? params.get("page") : 1,
      filter:
        params.get("q") != null
          ? [{ name: params.get("q"), operator: "=", value: params.get("v") }]
          : null,
      events: [],
      eventsCalled: false,
      message: null,
      paginatedEvents: [],
      eventsPerPage: 6,
    };
  }

  getData() {
    this.setState({ eventsCalled: false });
    const thisClass = this;
    EventService.getEvents(this.state.filter)
      .then((res) => {
        if (res["data"]["data"].length === 0) {
          this.setState({
            message: (
              <center>
                <h1 className="mt-20 mb-20 text-4xl text-gray-300 dark:text-gray-600 font-bold">
                  Sin resultados
                </h1>
              </center>
            ),
          });
        }

        const pastEvents = res["data"]["data"].filter((event) =>
          isDatePast(event.start_date, event.end_date)
        );
        const futureEvents = res["data"]["data"].filter(
          (event) =>
            !isDatePast(event.start_date, event.end_date) &&
            !isDateDuring(event.start_date, event.end_date) &&
            !isDateToday(event.start_date, event.end_date)
        );
        const nowEvents = res["data"]["data"].filter((event) =>
          isDateDuring(event.start_date, event.end_date)
        );
        const todayEvents = res["data"]["data"].filter((event) =>
          isDateToday(event.start_date, event.end_date)
        );

        thisClass.setState({
          eventsCalled: true,
          events: [
            {
              today: todayEvents,
              past: pastEvents,
              now: nowEvents,
              future: futureEvents,
            },
          ],
          paginatedEvents: paginateResults(
            res["data"]["data"],
            thisClass.state.eventsPerPage,
            thisClass.state.actual_page
          ),
        });
      })
      .catch(function (error) {
        let messageError = "Error desconocido";
        if (error.response.status === 401) {
          window.location.href = "/login";
          messageError = "La sesión expiró, recarga la página";
        }
        thisClass.setState({
          eventsCalled: true,
          message: (
            <center>
              <h1 className="text-4xl text-red-500 font-bold">
                {messageError}
              </h1>
            </center>
          ),
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  handlePageChange = (page) => {
    this.setState({
      actual_page: page,
      paginatedEvents: paginateResults(
        this.state.events,
        this.state.eventsPerPage,
        page
      ),
    });
  };

  onSubmitSearchHandler = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const filter =
      form.get("value") !== "" && form.get("value") != null
        ? [
            {
              name: form.get("option"),
              operator: "=",
              value: form.get("value"),
            },
          ]
        : null;

    this.setState(
      {
        actual_page: 1,
        filter: filter,
      },
      () => {
        this.getData();
      }
    );
  };

  render() {
    return (
      <BaseLayout className="!px-0">
        <div>
          <center>
            <h1 style={{ fontSize: "4.5em" }}>Eventos</h1>
          </center>
          <div>
            {!this.state.eventsCalled && <Loader className="mb-20 mt-20" />}
            {this.state.eventsCalled &&
              this.state.message != null &&
              this.state.message}
            {this.state.eventsCalled && this.state.events.length > 0 && (
              <React.Fragment>
                <div className=" transition delay-150 duration-300 mb-6">
                  <div className="m-2 space-y-2">
                    {this.state.events[0].now.length > 0 && (
                        <div className="px-1">
                          <div className="animate-pulse animate-infinite">
                            <FontAwesomeIcon
                              className="text-red-500"
                              icon={faSatelliteDish}
                            />{" "}
                            Eventos ahora mismo
                          </div>
                          <div>
                            {this.state.events[0].now.map((event) => {
                              return (
                                <Link key={event.id} to={"/event/" + event.id}>
                                  <EventsListItem
                                    className=""
                                    eventInfo={event}
                                  />
                                </Link>
                              );
                            })}
                          </div>
                          <hr className="my-7 border-gray-500" />
                        </div>
                      )}
                    {this.state.events[0].today.length > 0 && (
                      <GroupOfEvents
                        events={this.state.events[0].today}
                        index="1"
                        groupName="Eventos de hoy"
                        icon="/assets/img/today_event.svg"
                      />
                    )}
                    {this.state.events[0].future.length > 0 && (
                      <GroupOfEvents
                        events={this.state.events[0].future}
                        index="1"
                        groupName="Próximos eventos"
                        icon="/assets/img/next_event.svg"
                      />
                    )}
                    {this.state.events[0].past.length > 0 && (
                      <GroupOfEvents
                        events={this.state.events[0].past}
                        index="1"
                        groupName="Eventos pasados"
                        icon="/assets/img/past_event.svg"
                      />
                    )}
                  </div>
                </div>
                <Paginator
                  perPage={this.state.eventsPerPage}
                  numTotal={this.state.events.length}
                  onPageChange={this.handlePageChange}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </BaseLayout>
    );
  }
}
