const options = { year: "numeric", month: "short", day: "numeric" };

export const printDate = (date, time = null, opt = null) => {
  if (date == null) {
    return "-";
  }

  const ownOptions = opt ? opt : options;
  if (time === true) {
    ownOptions.hour = "2-digit";
    ownOptions.minute = "2-digit";
  }
  const dateA = new Date(date);
  const dateR = dateA.toLocaleDateString("es-ES", ownOptions);
  return dateR !== "Invalid Date" ? dateR : "-";
};

export const isDatePast = (start_date, end_date) => {
  const actual = new Date();
  const end = new Date(end_date);
  const difference = actual.getTime() - end.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));

  return days > 0;
};

export const isDateDuring = (start_date, end_date) => {
  const actual = new Date();
  const start = new Date(start_date);
  const end = new Date(end_date);
  const difference = actual.getTime() - end.getTime();
  const during =
    end.getTime() >= actual.getTime() && actual.getTime() >= start.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));

  return days === 0 && during;
};

export const isDateToday = (start_date, end_date) => {
  const actual = new Date();
  const end = new Date(end_date);
  const difference = actual.getTime() - end.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));

  return days === 0;
};

export const calculateAge = (fecha) => {
  if (fecha == null) {
    return "-";
  }

  const hoy = new Date();
  const cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }
  return edad ? edad + (edad === 1 ? " año" : " años") : "-";
};

export const calculateEnrollment = (date) => {
  const today = new Date();
  const enroll_date = new Date(date);
  let years = today.getFullYear() - enroll_date.getFullYear();
  let months = today.getMonth() - enroll_date.getMonth();
  months = months ? months : 0;
  let senority = "";

  if (months < 0 || (months === 0 && today.getDate() < enroll_date.getDate())) {
    years--;
  }

  if (years > 0) {
    senority += years + (years === 1 ? " año " : " años ");
  }

  if (months !== 0) {
    if (months < 0) {
      months = 12 + months;
    }
    senority += months + (months === 1 ? " mes" : " meses");
  }

  return senority ? senority : "-";
};
