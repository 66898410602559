import "./scanner.css";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { TextInput, Button, Loader } from "../../../Components";
import PopUp from "./PopUp";
import AttendanceService from "../../../Services/AttendanceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRotate } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

const QrScanner = (props) => {
  const eventId = props.eventId;
  const [selected, setSelected] = useState("environment");
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState([]);
  const [popUpDisplay, setPopUpDisplay] = useState(false);
  const [nifInput, setNifInput] = useState("");
  const handleScan = async (scanData) => {
    if (scanData && scanData !== "") {
      setLoadingScan(true);
      // setPrecScan(scanData);
      AttendanceService.searchAttendanceQR(props.eventId, scanData)
        .then((res) => {
          const result = Array.isArray(res.data.data)
            ? res.data.data[0]
            : res.data.data;
          setData(result);
          setPopUpDisplay(true);
          setLoadingScan(false);
        })
        .catch(function (error) {
          setLoadingScan(false);
          if (error.response.status === 401) {
            toast.error(
              "La sesión ha expirado será redirigido a la página de login."
            );
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.message ?? error.message);
            console.log(error);
          }
        });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const searchNIF = () => {
    setLoadingScan(true);
    AttendanceService.searchAttendance(props.eventId, nifInput)
      .then((res) => {
        const result = Array.isArray(res.data.data)
          ? res.data.data[0]
          : res.data.data;
        setData(result);
        setPopUpDisplay(true);
        setLoadingScan(false);
      })
      .catch(function (error) {
        setLoadingScan(false);
        if (error.response.status === 401) {
          toast.error(
            "La sesión ha expirado será redirigido a la página de login."
          );
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.message ?? error.message);
          console.log(error);
        }
      });
  };

  const handleChange = (event) => {
    setNifInput(event.target.value);
  };

  const closePopUp = () => {
    setPopUpDisplay(false);
  };

  const toggleCamera = () => {
    setSelected(selected === "environment" ? "user" : "environment");
  };

  return (
    <React.Fragment>
      {popUpDisplay && (
        <PopUp scanData={data} eventId={eventId} closeCallBack={closePopUp} />
      )}
      {loadingScan && (
        <div
          className="w-full min-h-screen"
          style={{
            position: "absolute",
            top: "0",
            zIndex: "2",
            left: "0",
            backgroundColor: "rgba(0,0,0,0.7)",
          }}
        >
          <Loader className="mt-80 w-auto" />
        </div>
      )}
      {!popUpDisplay && (
        <div className="App">
          {
            <React.Fragment>
              <FontAwesomeIcon
                className="text-4xl mb-5 text-gray-600"
                onClick={toggleCamera}
                icon={faCameraRotate}
              />
              <QrReader
                facingMode={selected}
                delay={1000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: "300px" }}
              />
              <TextInput
                onChange={handleChange}
                name="nifInput"
                className="py-5 px-4 text-2xl mt-5 !bg-gray-300 !text-black"
                placeHolder="Introduce un DNI"
              />
              <Button
                onClick={searchNIF}
                className="mt-2 font-extrabold !text-2xl !py-5 !px-4 !w-40"
              >
                Buscar
              </Button>
            </React.Fragment>
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default QrScanner;
