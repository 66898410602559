import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login/Login";
import { Events, EventInformation } from "./Pages/Events";
import { PrivateRoute } from "./Components/PrivateRoute";
import { AuthProvider } from "./Components/AuthContext";
import axios from "axios";
import { Toaster } from "react-hot-toast";

axios.defaults.withCredentials = true;
const token = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.cache = false;

function App() {
	return (
    <AuthProvider>
      <Toaster
        position="top-center"
        toastOptions={{
          className:
            " border-2 !border-gray-400 text-2xl !w-full !border-gray-800 !bg-gray-700 text-gray-200 !rounded-3xl",
          style: {
            background: "rgb(55 65 81 / var(--tw-bg-opacity))",
            color: "rgb(229 231 235 / var(--tw-text-opacity))",
            padding: "1rem",
            borderRadius: "100%",
          },
        }}
      />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate to="/events" />
              </PrivateRoute>
            }
          />
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            }
          />
          <Route
            path="/event/:eventId"
            element={
              <PrivateRoute>
                <EventInformation />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
