import React, { Component } from "react";
import { AttendanceService } from "../../../Services";
import { Loader, TextInput, Paginator } from "../../../Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { paginateResults } from "../../../Utils";
import MemberListItem from "./MemberListItem";

export default class MembersInEvent extends Component {
  eventId = null;

  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);

    this.eventId = props.eventId;

    this.state = {
      event: [],
      actual_page: params.get("page") != null ? params.get("page") : 1,
      members: [],
      lists: [],
      membersCalled: false,
      message: "",
      uploadingListFileModal: false,
      searchValue: params.get("search") != null ? params.get("search") : "",
      loadValue: 0,
      selectedFile: null,
      uploadButton: true,
      loadResult: "",
      errorMessage: "",
      uploadCheckbox: true,
      paginatedMembers: [],
      membersPerPage: 10,
    };
  }

  onSubmitSearchHandler = (event) => {
    event.preventDefault();
    const searchVal = event.target.search.value;
    this.setState(
      {
        actual_page: 1,
        searchValue: searchVal,
      },
      () => {
        this.getAttendanceList();
      }
    );
  };

  getAttendanceList() {
    const search = this.state.searchValue ? true : false;
    const eventId = this.eventId;

    this.setState({ membersCalled: false }, () => {
      setTimeout(() => {

        const attendanceServiceCall = search
          ? AttendanceService.searchAttendance(eventId, this.state.searchValue)
          : AttendanceService.getAttendanceList(eventId)

        attendanceServiceCall
          .then((response) => {
            const data = search ? response.data.data.members : response.data.members;
            this.showResults(data);
          })
          .catch((error) => {
            this.setState({
              membersCalled: true,
              message: this.errorMessageHtml,
            });
          });
      
      }, 500)
    })
  }

  showResults(results) {
    if (results.length === 0) {

      this.setState({
        membersCalled: true,
        message: (
          <center>
            <h1 className="text-4xl text-gray-300 dark:text-gray-600 font-bold mt-20 mb-20">
              Sin resultados
            </h1>
          </center>
        ),
      });
    } else {
      this.setState({
        membersCalled: true,
        members: results,
        paginatedMembers: paginateResults(
          results,
          this.state.membersPerPage,
          this.state.actual_page
        ),
      });
    }
  }

  handlePageChange = (page) => {
    this.setState({
      actual_page: page,
      paginatedMembers: paginateResults(
        this.state.members,
        this.state.membersPerPage,
        page
      ),
    });
  };

  refresh = () => {
    this.getAttendanceList()
  }

  componentDidMount() {
    this.getAttendanceList();
  }

  render = () => {
    return (
      <div className="flex flex-col">
        <div className="-my-2">
          <div className="py-2 align-middle block min-w-full">
            {!this.state.membersCalled && <Loader className="mb-20 mt-20" />}
            {this.state.membersCalled &&
              this.state.message != null &&
              this.state.message}
            {this.state.membersCalled &&
              this.state.paginatedMembers.length > 0 && (
                <React.Fragment>
                  <div className="flex flex-wrap justify-between -m-1.5 mb-1">
                    <div className="w-full md:w-auto p-1.5 mt-3 mb-4 flex items-center">
                      <form
                        onSubmit={this.onSubmitSearchHandler}
                        className="flex-grow"
                      >
                        <TextInput
                          id="search-dropdown"
                          type="search"
                          name="search"
                          placeholder="Buscar..."
                          defaultValue={this.state.searchValue}
                          className="rounded-xl text-sm py-2 !bg-white !text-gray-800 px-2"
                        />
                        <button
                          type="submit"
                          className=" border-l-0 dark:border-gray-700 rounded-none rounded-r-lg px-4 py-2"
                        >
                          <FontAwesomeIcon
                            className="text-lg"
                            icon={faMagnifyingGlass}
                          />
                          <span className="sr-only">Buscar</span>
                        </button>
                    </form>
                      <button
                          className="ml-auto h-full !bg-inherit border justify-self-end rounded-lg w-10 right-0"
                          onClick={() => { this.getAttendanceList() }}
                      >
                          <FontAwesomeIcon className="text-md text-white" icon={faArrowsRotate} />
                      </button>
                    </div>
                  </div>
                  <div className="!overflow-x-auto transition delay-150 duration-300 sm:rounded-lg mb-6">
                  {this.state.paginatedMembers.map((member) => {
                      return (
                        <MemberListItem
                          key={member.member.id}
                          eventId={this.eventId}
                          memberInfo={member}
                          onUpdate={this.refresh}
                        />
                      );
                    })}
                  </div>
                  <center>
                    <Paginator
                      perPage={this.state.membersPerPage}
                      numTotal={this.state.members.length}
                      onPageChange={this.handlePageChange}
                    />
                  </center>
                  <br />
                </React.Fragment>
              )}
          </div>
        </div>
      </div>
    );
  };
}
