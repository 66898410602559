import Navbar from "./Navbar";
import React from "react";
import "./base.css";

export default function BaseLayout({ user, header, className, children }) {
  return (
    <React.Fragment>
      <Navbar />
      <main className={className + " min-h-screen pt-20"}>{children}</main>
    </React.Fragment>
  );
}
