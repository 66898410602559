import React, { Component } from "react";
import EventsListItem from "./EventListItem";
import { Link } from "react-router-dom";

export default class GroupOfEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: this.props.events,
      groupName: this.props.groupName,
        index: this.props.index,
      icon: this.props.icon
    };
  }

  render() {
    return (
      <div
        className="group flex flex-col gap-2 rounded-lg bg-gray-500 pb-1 pt-3 text-white"
        tabIndex={this.state.index}
      >
        <div className="flex cursor-pointer items-center justify-between px-5">
          <span><img src={this.state.icon} className="h-6 float-left flex align-middle mr-1" alt={this.state.groupName} /> {this.state.groupName} </span>
          <img
            alt="downarrow"
            src="https://upload.wikimedia.org/wikipedia/commons/9/96/Chevron-icon-drop-down-menu-WHITE.png"
            className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
          />
        </div>
        <div className="invisible px-3 h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
          {this.state.events.map((event) => {
            return (
              <Link key={event.id} to={"/event/" + event.id}>
                <EventsListItem
                  className=""
                  eventInfo={event}
                />
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}
