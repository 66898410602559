import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AttendanceService } from "../../../Services";
import toast from "react-hot-toast";
import {
  faSackDollar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

export default class MemberListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      member: props.memberInfo.member,
      eventId: props.eventId,
      memberStatus: props.memberInfo.status,
      afterUpdate: props.afterUpdate
    };
  }

  changeAttendanceState = () => {
    toast.promise(
      AttendanceService.setAttendance(this.state.eventId, this.state.member.id),
      {
        loading: "Procesando petición...",
        success: (response) => {
          this.props.onUpdate()
          return "¡Hecho!";
        },
        error: (err) => err.response.data.message ?? err.message,
      }
    );
  };

  render() {
    const informationIncomplete =
      this.state.member.email === null ||
      this.state.member.email === "" ||
      this.state.member.email === "null" ||
      this.state.member.phone === null ||
      this.state.member.phone === "" ||
      this.state.member.phone === "null" ||
      this.state.member.address === null ||
      this.state.member.address === "" ||
      this.state.member.address === "null" ||
      this.state.member.birthday === null ||
      this.state.member.birthday === "" ||
      this.state.member.birthday === "null" ||
      this.state.member.enrollement_date === null ||
      this.state.member.enrollement_date === "" ||
      this.state.member.enrollement_date === "null";

    const itemStyle = this.state.memberStatus === "in" ? "!bg-green-700 " : "";

    return (
      <div
        key={this.state.member.id}
        className={
          this.props.className +
          " " +
          itemStyle +
          " py-2 px-3 mb-2 flex cursor-pointer rounded-lg bg-gray-600 hover:bg-gray-700 dark:text-gray-400"
        }
      >
        <div className="w-1/6 flex items-center">
          <input
            checked={this.state.memberStatus === "in" ? "true " : ""}
            onChange={this.changeAttendanceState}
            id="default-checkbox"
            type="checkbox"
            value=""
            className="w-9 h-9 !border-none !text-green-600 accent-green-700 bg-gray-100 !checked:bg-green-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
        <div className="grid grid-cols-6 w-full">
          <div className="col-span-4 px-1 text-white">
            {this.state.member.name + " " + this.state.member.lastname}
          </div>
          <div className="items-end justify-end text-gray-300 grid col-span-2 ">
            <div className="">{this.state.member.person_id}</div>
            <div className="flex justify-end">
              {informationIncomplete && (
                <div className="bg-yellow-500 text-white p-1 flex rounded-full w-5 h-5 mr-1">
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={faTriangleExclamation}
                  />
                </div>
              )}
              <div
                className={
                  (this.state.member.payment_up_to_date
                    ? "bg-green-600"
                    : "bg-red-600") +
                  " p-1 flex rounded-full w-5 h-5 text-white"
                }
              >
                <FontAwesomeIcon className="text-xs" icon={faSackDollar} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
