import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_PARENT_APP_HOST + "/api/event";
class EventServices {
  async getEvents(filter = null) {
    return await axios.get(API_BASE_URL + "/get", {
      params: { filter: filter, per_page: 5 },
    });
  }

  async createEvent(event) {
    return await axios.post(API_BASE_URL + "/create", event, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${event._boundary}`,
      },
    });
  }

  async getEventById(eventId) {
    return axios.get(API_BASE_URL + "/get", { params: { id: eventId } });
  }

  async updateEvent(event) {
    event.append("_method", "PUT");
    return await axios.post(API_BASE_URL + "/update", event, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${event._boundary}`,
      },
    });
  }

  async deleteEvent(eventId) {
    return await axios.delete(API_BASE_URL + "/delete", { data: { id: eventId } });
  }
}

const EventService = new EventServices();
export default EventService;
