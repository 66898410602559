import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../Layouts/base.css";
import { useAuth } from "../../Components/AuthContext";
import { Loader } from "../../Components";

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginCall, setLoginCall] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_PARENT_APP_HOST + "/api/login";
    setLoginCall(true);
    await axios
      .post(
        url,
        {
          email: formData.email,
          password: formData.password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("Logged!");
        login(response.data.token);
        setLoginCall(false);
        navigate("/");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          window.location.href = "/login";
          setErrorMsg("La sesión expiró, recarga la página");
        }
        setErrorMsg(error.message);
        console.error("Inicio de sesión fallido", error);
        setLoginCall(false);
      });
  };

  return (
    <main className="min-h-screen flex justify-center items-center">
      <div className="bg-gray-800 border-gray-700 p-8 rounded-lg shadow-md w-full sm:w-96">
        <div className="text-center mb-8">
          <img
            src={
              process.env.REACT_APP_PARENT_APP_HOST + "/assets/img/electra.png"
            }
            alt="Logo de la aplicación"
            className="mx-auto w-20 h-20"
          />
          <h2 className="text-3xl font-semibold mt-4">Electra App</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <label className="text-red-700 mb-2">{errorMsg}</label>
          <div className="mb-6">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full h-15 text-lg px-4 py-4 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700"
              placeholder="Correo Electrónico"
              required
            />
          </div>
          <div className="mb-6">
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full h-15 text-lg px-4 py-4 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700"
              placeholder="Contraseña"
              required
            />
          </div>
          <button
            type="submit"
            disabled={loginCall && "disabled"}
            className={
              (loginCall && "text-white bg-blue-300") +
              " w-full bg-blue-500 text-white py-4 !h-15 text-xl font-bold rounded-lg flex items-center justify-center align-middle" +
              (!loginCall && "hover:bg-blue-600")
            }
          >
            {loginCall && (
              <Loader className="!w-5 !h-5 mr-2 static" />
            )}
            Iniciar Sesión
          </button>
        </form>
      </div>
    </main>
  );
};

export default LoginPage;
