import React, { Component } from "react";

export default class Paginator extends Component {

    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search)
        const actualPage = (params.get("page") != null)? params.get("page") : 1
        let perpage_c = (props.perPage === 'undefined' || props.perPage === 0 || props.perPage == null || props.perPage === '')? 15 : props.perPage
      
        this.state = {
            page: actualPage,
            total_pages: props.numTotal/perpage_c,
            per_page: perpage_c,
            total: props.numTotal,
            is_first_page: (actualPage <= 1),
            is_last_page: (actualPage >= (props.numTotal/perpage_c))
        };
    }

    goToPage(newPage)
    {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const paramPage = urlSearchParams.get('page');

        if (!paramPage) {
            urlSearchParams.append('page', this.state.actualPage);
          } else {
            urlSearchParams.set('page', newPage.toString());
        }
        
        this.setState({
            page: newPage,
            is_first_page: (newPage <= 1),
            is_last_page: (newPage >= (this.state.total/this.state.per_page))
        })
        
        window.history.pushState(
          null,
          "",
          window.location.pathname + "?" + urlSearchParams.toString()
        );

        this.props.onPageChange(newPage)
    }

    render() {
        const numberOfPagesLimit = 5
        const startLimit = 2
        const endLimit = 1
        let pages = []
        const nextPage =  Number(this.state.page)+1
        const prevPage = this.state.page-1

        var first_class = (!this.state.is_first_page)? "" : " rounded-l-lg ";
        var last_class = (!this.state.is_last_page)? "" : " rounded-r-lg ";

        for (let i = 0; i < this.state.total_pages; i++) {
            let extraClass = (i === 0 && first_class)? first_class : '';
            extraClass = (i >= this.state.total_pages-1 && last_class)? last_class : extraClass;
            if (i === this.state.page-1) {
                extraClass += " !bg-gray-700 !text-white  "
            }
            pages.push(
              <li key={i}>
                <button
                  style={{ height: "60px", width: "60px" }}
                  type="button"
                  onClick={() => this.goToPage(i + 1)}
                  className={
                    extraClass +
                    "px-3 py-2 text-2xl items-center justify-center inline-flex leading-tight border bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white"
                  }
                >
                  {i + 1}
                </button>
              </li>
            );
        }

        if (this.state.total_pages > numberOfPagesLimit) {

            let startGroupStartNumber = 0
            let startGroupEndNumber = startLimit
            let endGroupStartNumber = this.state.total_pages - endLimit +1
            let endGroupEndNumber = this.state.total_pages+1

            const dotsButton = (
              <li key="dots">
                <button
                  style={{ height: "60px", width: "60px" }}
                  type="button"
                  className={
                    "px-3 py-2 text-2xl items-center justify-center inline-flex leading-tight  border bg-gray-800 border-gray-700 text-gray-400"
                  }
                >
                  ...
                </button>
              </li>
            );

            if (this.state.page < startLimit)
            {
                startGroupStartNumber = 0
                startGroupEndNumber = startLimit

                const startGroup = pages.slice(startGroupStartNumber ,startGroupEndNumber)
                pages = startGroup
                pages.push(dotsButton)

            } else if (this.state.page >= startLimit && this.state.page <= this.state.total_pages - endLimit +1)
            {
                startGroupStartNumber = this.state.page - startLimit
                startGroupEndNumber = this.state.page

                const startGroup = pages.slice(startGroupStartNumber ,startGroupEndNumber)
                const endGroup = pages.slice(endGroupStartNumber, endGroupEndNumber)
                pages = startGroup
                pages.push(dotsButton)
                pages = pages.concat(endGroup)

            } else
            {
                const endGroup = pages.slice(endGroupStartNumber, endGroupEndNumber)
                pages = []
                pages.push(dotsButton)
                pages = pages.concat(endGroup)
            }
        }

        return (
          !(this.state.page !== 0) || (
            <div aria-label="Page navigation">
              <ul className="inline-flex -space-x-px">
                {this.state.is_first_page || (
                  <li>
                    <button
                      style={{ height: "60px" }}
                      type="button"
                      onClick={() => this.goToPage(prevPage)}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium  border  rounded-l-lg   bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-20 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                )}
                {this.state.total_pages <= 1 || pages}
                {this.state.is_last_page || (
                  <li>
                    <button
                      style={{ height: "60px" }}
                      type="button"
                      onClick={() => this.goToPage(nextPage)}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium border rounded-r-lg  bg-gray-800 border-gray-700 text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 ml-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )
        );
    }
}
