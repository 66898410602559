import React, { useEffect, useState } from "react";
import EventService from "../../Services/EventService";
import BaseLayout from "../../Layouts/BaseLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faRectangleList,
  faQrcode,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Loader, Button } from "../../Components";
import MembersInEvent from "./components/MembersInEvent";
import EventResume from "./components/EventResume";
import QrScanner from "./components/ScannerEvent";

export default function EventInformation(props) {
  const navigate = useNavigate();
  const [event, setEvent] = useState();

  let { eventId } = useParams();

  const [content, setContent] = useState();

  const changeTo = (component) => {
    switch (component) {
      case "info":
        setContent(<EventResume event={event} />);
        break;
      case "list":
        setContent(
          <MembersInEvent eventId={event.id} />
        );
        break;
      case "scanner":
        setContent(<QrScanner eventId={event.id} />);
        break;
      default:
        setContent(<h2>No se ha seleccionado nada</h2>);
        break;
    }
  };

  const getEvent = (id) => {
    EventService.getEventById(id)
      .then((res) => {
        setEvent(res.data.data);
        const eventId = res.data.data.id
        const component =
          res.data.data.acta === 0 ? (
            <QrScanner eventId={eventId} />
          ) : (
            <EventResume event={res.data.data} />
          );
        setContent(component);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
        }
        console.log(error);
      });
  };

  useEffect(() => {
    getEvent(eventId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);


  return (
    <BaseLayout>
      {!event && <Loader className="mb-20 mt-20" />}
      {event && (
        <div>
          <center className="mb-5">
            <h1 className="text-3xl leading-none">{event.title}</h1>
            {event.acta === 1 && (
              <div className="p-1.5 w-full">
                <div className="bg-gray-600 rounded-xl font-bold py-2 w-full text-center text-white">
                  <FontAwesomeIcon className="text-lg mr-2" icon={faLock} />
                  Acta cerrada
                </div>
              </div>
            )}
          </center>
          <div
            className={
              (event.acta === 0
                ? "grid-cols-3"
                : "grid-cols-2") + " grid gap-1 place-items-center"
            }
          >
            <div className="w-full">
              <Button
                className="w-full"
                onClick={() => {
                  changeTo("info");
                }}
              >
                <FontAwesomeIcon className="text-3xl" icon={faCircleInfo} />
              </Button>
            </div>
            <div className="w-full">
              <Button
                className="w-full"
                onClick={() => {
                  changeTo("list");
                }}
              >
                <FontAwesomeIcon className="text-3xl" icon={faRectangleList} />
              </Button>
            </div>
            {event.acta === 0 && (
              <div className="w-full">
                <Button
                  className="w-full"
                  onClick={() => {
                    changeTo("scanner");
                  }}
                >
                  <FontAwesomeIcon className="text-3xl" icon={faQrcode} />
                </Button>
              </div>
            )}
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <div>{content}</div>
        </div>
      )}
    </BaseLayout>
  );
}
