import React, { Component } from "react";
import Loader from "../../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStopwatch,
  faCalculator,
  faListCheck,
  faAlignLeft,
  faClock,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default class EventResume extends Component {
  months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  constructor(props) {
    super(props);

    this.state = {
      event: props.event,
      loading: false,
    };
  }

  calculateDuration(start_date, end_date) {
    const end = new Date(start_date);
    const start = new Date(end_date);
    const difference = start.getTime() - end.getTime();
    return Math.ceil(difference / 1000 / 60);
  }

  render() {
    console.log(this.state.event)
    const data = {
      labels: ["Dentro", "Convocados"],
      datasets: [
        {
          label: "nº de Miembros",
          data: [
            this.state.event.attendInfo.attend,
            this.state.event.attendInfo.total -
              this.state.event.attendInfo.attend,
          ],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
          ],
          borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          borderWidth: 1,
        },
      ],
    };

    let startDate = new Date(this.state.event.start_date);
    startDate =
      startDate.getDate() +
      " de " +
      this.months[startDate.getMonth()] +
      " de " +
      startDate.getFullYear();

    let startHour = new Date(this.state.event.start_date);
    startHour =
      startHour.getHours() + ":" + ((startHour.getMinutes() < 10)
        ? ("0" + startHour.getMinutes())
        : startHour.getMinutes());

    let endHour = new Date(this.state.event.end_date);
    endHour =
      endHour.getHours() +
      ":" +
      (endHour.getMinutes() < 10
        ? "0" + endHour.getMinutes()
        : endHour.getMinutes());

    return (
      <React.Fragment>
        {this.state.loading && <Loader type="resumeEvent" />}
        {!this.state.loading && (
          <div className="grid grid-cols-1 gap-5">
            <div className="dark:text-white">
              <span className="text-gray-400">
                <FontAwesomeIcon icon={faAddressBook} /> Lista utilizada
              </span>
              <div className="text-xs">{this.state.event.list.name}</div>
            </div>
            <div className="grid grid-cols-2 gap-x-2">
              <div className="dark:text-white">
                <span className="text-gray-400">
                  <FontAwesomeIcon icon={faClock} /> Fecha
                </span>
                <div className="text-xs">{startDate}</div>
              </div>
              <div className="dark:text-white">
                <span className="text-gray-400">
                  <FontAwesomeIcon icon={faClock} /> Hora
                </span>
                <div className="text-xs">{startHour + "h. - " + endHour + 'h.'}</div>
              </div>
            </div>
            <div className="grid grid-cols-3 text-gray-100 gap-y-4">
              <div>
                <span className="text-gray-400">
                  <FontAwesomeIcon icon={faCalculator} /> Invitados
                </span>
                <div className="text-xs w-full">
                  {this.state.event.attendInfo.attend +
                    "/" +
                    this.state.event.attendInfo.total}
                </div>
              </div>
              <div>
                <span className="text-gray-400">
                  <FontAwesomeIcon icon={faStopwatch} /> Duración
                </span>
                <div className="text-xs">
                  {this.calculateDuration(
                    this.state.event.start_date,
                    this.state.event.end_date
                  )}{" "}
                  min.
                </div>
              </div>
              <div>
                <span className="text-gray-400">
                  <FontAwesomeIcon icon={faListCheck} /> Asistencia
                </span>
                <div className="text-xs">
                  {(
                    (this.state.event.attendInfo.attend * 100) /
                    this.state.event.attendInfo.total
                  ).toFixed(2)}{" "}
                  %
                </div>
              </div>
            </div>
            <div className="dark:text-white">
              <span className="text-gray-400">
                <FontAwesomeIcon icon={faAlignLeft} /> Descripción
              </span>
              <div className="text-xs">{this.state.event.description}</div>
            </div>
            <Doughnut data={data} />
          </div>
        )}
      </React.Fragment>
    );
  }
}
