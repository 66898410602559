import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faEllipsis,
  faCalendarCheck,
  faHandPointRight,
  faLock
} from "@fortawesome/free-solid-svg-icons";

export default class EventsListItem extends Component {
  months = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];
  options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

  constructor(props) {
    super(props);

    this.state = {
      event: this.props.eventInfo,
      attendanceNum: "-",
      totalMembersInvited: "-",
    };
  }

  eventSatus(cancelled, start_date, end_date) {
    if (cancelled) {
      return (
        <span className="text-red-700 font-bold">
          <FontAwesomeIcon className="align-middle" icon={faXmark} /> Cancelado
        </span>
      );
    }

    const actual = new Date();
    const start = new Date(end_date);
    const end = new Date(start_date);
    const difference = actual.getTime() - end.getTime();
    const during = end.getTime() <= actual.getTime() <= start.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));

    switch (true) {
      case days === 0 && during:
        return (
          <span className="text-green-700 font-bold">
            <FontAwesomeIcon className="align-middle" icon={faEllipsis} /> en
            curso
          </span>
        );
      case days > 0:
        return (
          <span className="text-gray-500 font-bold">
            <FontAwesomeIcon className="align-middle" icon={faCalendarCheck} />{" "}
            celebrado
          </span>
        );
      case days === 0:
        return (
          <span className="text-yellow-700 font-bold">
            <FontAwesomeIcon className="align-middle" icon={faHandPointRight} />{" "}
            próximo
          </span>
        );
      default:
        return "";
    }
  }

  calculateDuration(start_date, end_date) {
    const end = new Date(start_date);
    const start = new Date(end_date);
    const difference = start.getTime() - end.getTime();
    return Math.ceil(difference / 1000 / 60);
  }

  render() {
    const startDate = new Date(this.props.eventInfo.start_date);

    return (
      <div
        className={
          this.props.className + ((this.state.event.acta ===
          1) ? 'opacity-40' : '') +
            " flex mb-3 py-2 pr-4 items-center justify-center py-5 px-3 cursor-pointer rounded-lg  bg-gray-700 hover:bg-gray-600 text-gray-400"
        }
      >
        <div className="w-1/6 content-center justify-center text-white items-center">
          <div className="text-center text-3xl">{startDate.getDate()}</div>
          <div className="text-center text-bottom text-sm">
            {this.months[startDate.getMonth()]}
          </div>
          <div className="text-center text-xs">{startDate.getFullYear()}</div>
        </div>
        <div className="w-4/6 pl-4 border-l mr-2 border-gray-500">
          <span
            className="text-gray-100 font-bold text-lg tracking-tigh inline-block"
            style={{ lineHeight: "1" }}
          >
            {this.state.event.title}{" "}
          </span>
          <br />
          <span className="text-xs">Lista de invitados:</span>{" "}
          <div
            className="text-gray-300 text-xs w-full"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {this.state.event.list.name}
          </div>
        </div>
        <div className="w-1/6 flex text-center content-center justify-center items-center">
          {this.state.event.acta === 0 && (
            <div className="text-xl flex items-center justify-center text-gray-600 bg-white content-center rounded-2xl px-3 h-7">
              {(
                (this.state.event.attendInfo.attend * 100) /
                this.state.event.attendInfo.total
              ).toFixed(0)}
              <span className="text-xs flex">%</span>
            </div>
          )}
          {this.state.event.acta === 1 && (
            <FontAwesomeIcon className="align-middle text-3xl" icon={faLock} />
          )}
        </div>
      </div>
    );
  }
}
