import { forwardRef, useEffect, useRef } from 'react';

export default forwardRef(function TextInput({
    type = 'text',
    className = '',
    isFocused = false,
    handleChange = '',
    placeHolder = '',
    ...props
    }, ref) {
    const input = useRef(null);

    const onChangeFunction = (handleChange) ? (e) => handleChange(e) : '';

    useEffect(() => {
      if (isFocused) {
        input.current.focus();
      }
    }, [isFocused]);

    if (onChangeFunction !== '') {
        return (
            <input
                {...props}
                type={type}
                placeholder={placeHolder}
                onChange={onChangeFunction}
                className={ className +
                    ' border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-md shadow-sm '
                }
                ref={input}
            />
        );
    } else {
        return (
            <input
                {...props}
                type={type}
                placeholder={placeHolder}
                className={ className +
                    ' border-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-indigo-500 dark:focus:border-indigo-600 focus:ring-indigo-500 dark:focus:ring-indigo-600 rounded-md shadow-sm '
                }
                ref={input}
            />
        );
    }
});
