import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Components/AuthContext";

function Navbar() {
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOMenuOpen, setIsOMenuOpen] = useState(false);
  const authUser = JSON.parse(localStorage.getItem("user"));

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleOMenu = () => {
    setIsOMenuOpen(!isOMenuOpen);
  };

  const closeSession = () => {
    logout()
    window.location.replace("/login")
  };

  return (
    <React.Fragment>
      <div className="fixed top-0 z-50 w-full border-b bg-gray-800 border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => {
                  toggleMenu();
                }}
                className="inline-flex items-center p-2 text-sm  rounded-lg sm:hidden  focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="https://flowbite.com" className="flex ml-2 md:mr-24">
                <img
                  src={
                    process.env.REACT_APP_PARENT_APP_HOST +
                    "/assets/img/electra.png"
                  }
                  className="h-8 mr-3"
                  alt="Electra Logo"
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-white">
                  Electra
                </span>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ml-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4  focus:ring-gray-600"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                    onClick={() => {
                      toggleOMenu();
                    }}
                  >
                    <img
                      className="w-8 h-8 rounded-full"
                      src="/profile_default.png"
                      alt="user"
                    />
                  </button>
                </div>
                <div
                  className={
                    (!isOMenuOpen ? "hidden" : "") +
                    " z-50 absolute transition-transform right-1 top-1 my-14 text-base list-none  divide-y  rounded shadow bg-gray-700 divide-gray-600"
                  }
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-white"
                      role="none"
                    >
                      {authUser.name}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-300"
                      role="none"
                    >
                      {authUser.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm   text-gray-300 hover:bg-gray-600 hover:text-white"
                        role="menuitem"
                      >
                        Perfil
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="block px-4 py-2 text-sm   text-gray-300 hover:bg-gray-600 hover:text-white"
                        role="menuitem"
                      >
                        Ajustes
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          closeSession();
                        }}
                        className="block w-full text-left px-4 py-2 text-sm   text-gray-300 hover:bg-gray-600 hover:text-white"
                        role="menuitem"
                      >
                        Cerrar sesión
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aside
        id="logo-sidebar"
        className={
          (!isMenuOpen && "-translate-x-full") +
          " fixed top-0 left-0 z-40 w-64 h-screen transition-transform pt-20 border-r sm:translate-x-0 bg-gray-800 border-gray-700"
        }
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/events"
                className="flex items-center p-2  rounded-lg text-white hover:bg-gray-700 group"
              >
                <svg
                  className="flex-shrink-0 w-5 h-5  transition duration-75 text-gray-400 group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 18"
                >
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Eventos</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </React.Fragment>
  );
}

export default Navbar;
